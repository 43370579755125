import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Loader } from '@/components/common/Loader';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { useAuth } from '@/context/useAuth';
import { useGetCollectionsByUser } from '@/hooks/collection/useGetCollectionsByUser';
import { useGetChefRecipes } from '@/hooks/recipes/useGetChefRecipes';
import { usePagination } from '@/hooks/usePagination';
import { useGetUserSavedRecipes } from '@/hooks/user/useGetUserSavedRecipes';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { TabProfileNav } from '@/pages/user/TabProfileNav';
import { CollectionCard } from '@/pages/user/collections/CollectionCard';

export const ChefProfilePage = () => {
	const { fakeUserHasSubscription, user } = useAuth();
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState(
		searchParams.get('tab') === 'saved' ? 'saved' : 'created',
	);
	const [skip, setSkip] = useState(0);
	const take = defaultPortfolioPaginationOptions.take;

	const { chefRecipes, totalEntities } = useGetChefRecipes(
		user?.chef?.uuid ?? '',
		{ take, skip },
		true,
	);

	const { userSavedRecipes, refetchUserSavedRecipes, totalUserSavedEntities } =
		useGetUserSavedRecipes({ take, skip });

	const {
		collections,
		totalCollections,
		isLoadingCollections,
		isErrorCollections,
		errorCollections,
	} = useGetCollectionsByUser({ take, skip });

	const handlePaginationEntities = () => {
		switch (activeTab) {
			case 'created':
				return totalEntities;
			case 'saved':
				return totalUserSavedEntities;
			case 'collections':
				return totalCollections;
			default:
				return 0;
		}
	};

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities: handlePaginationEntities(),
			setSkip,
		});

	const resetSkip = () => setSkip(0);

	useEffect(() => {
		resetSkip();
	}, [activeTab]);

	useEffect(() => {
		if (searchParams.has('tab')) {
			setActiveTab('saved');
			refetchUserSavedRecipes();
			searchParams.delete('tab');
			setSearchParams(searchParams);
			return;
		}
	}, [activeTab, skip, searchParams]);

	if (isLoadingCollections && activeTab === 'collections') {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<Loader size={2} />
			</div>
		);
	}

	if (isErrorCollections && activeTab === 'collections') {
		return (
			<div className="flex justify-center items-center h-[40vh] text-alertRed">
				<p>Error: {errorCollections?.message}</p>
			</div>
		);
	}

	const savedRecipesCount = totalUserSavedEntities || 0;
	const createdRecipesCount = totalEntities || 0;

	return (
		<div className="flex flex-col items-center font-helveticaNeue">
			<div className="place-self-start w-full md:px-0 mb-6 gap-4 md:gap-6 lg:gap-8">
				<TabProfileNav
					savedRecipesCount={savedRecipesCount}
					createdRecipesCount={createdRecipesCount}
					collectionsCount={totalCollections}
					activeTab={activeTab}
					setActiveTab={setActiveTab}
					isChef={true}
					resetSkip={resetSkip}
					userHasSubscription={fakeUserHasSubscription}
				/>
			</div>

			<div className="grid justify-center gap-y-10 gap-x-2 min-[375px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-[repeat(6,235px)] w-full">
				{userSavedRecipes &&
					activeTab === 'saved' &&
					userSavedRecipes.data.map((recipe) => (
						<div
							data-cy="user-cards-saved-recipes"
							key={recipe.id}
							className="flex justify-center items-center"
						>
							<RecipesCard
								recipe={recipe}
								userHasSubscription={fakeUserHasSubscription}
							/>
						</div>
					))}
				{chefRecipes &&
					activeTab === 'created' &&
					chefRecipes.data.map((recipe) => (
						<div
							data-cy="user-cards-created-recipes"
							key={recipe.id}
							className="flex justify-center items-center"
						>
							<RecipesCard
								recipe={recipe}
								isChefProfile
								userHasSubscription={fakeUserHasSubscription}
								paginationOptions={{ take, skip }}
							/>
						</div>
					))}
				{activeTab === 'collections' &&
					collections.map((collection, index) => (
						<Link
							to={`/profile/collection/${collection?.uuid}`}
							data-cy="user-cards-collections"
							key={collection.id || index}
						>
							<CollectionCard collection={collection} />
						</Link>
					))}
			</div>

			{((savedRecipesCount > 0 && activeTab === 'saved') ||
				(createdRecipesCount > 0 && activeTab === 'created') ||
				(totalCollections > 0 && activeTab === 'collections')) && (
				<NavigationButtons
					currentPage={currentPage}
					totalPages={totalPages}
					handleNextClick={onHandleNext}
					handlePrevClick={onHandlePrevious}
				/>
			)}
		</div>
	);
};
