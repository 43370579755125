import { setUserId } from '@amplitude/analytics-browser';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { HubCallback, HubCapsule } from '@aws-amplify/core';
import { Auth as AuthAmplify, Hub } from 'aws-amplify';
import { createContext, useCallback, useEffect, useState } from 'react';

import { AuthContextProps, LoginData, User } from '@/context/interfaces';
import { notifyError } from '@/helper/notifications';
import pendo from '@/interface/pendo';
import { SuscriberPlanEnum } from '@/interface/subscription.interfaces';
import { getUser } from '@/service/api/user';
import {
	authenticateUser,
	logoutUser,
	refreshUserToken,
} from '@/service/cognito/cognito.services';

export const AuthContext = createContext<AuthContextProps | undefined>(
	undefined,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	const fakeUserHasSubscription = true;
	const userHasSubscription =
		!!user?.chef ||
		user?.subscription?.activePlan === SuscriberPlanEnum.ESSENTIAL ||
		user?.subscription?.activePlan === SuscriberPlanEnum.PRO;

	const queryParams = new URLSearchParams(window.location.search);
	const chef = queryParams.get('chef');
	const chefDecode = decodeURIComponent(chef ?? '');
	localStorage.setItem('chef', chefDecode);

	const fetchUser = useCallback(async () => {
		try {
			setLoading(true);
			const { session } = await refreshUserToken();
			if (!session) return;
			const user = await getUser(session.getAccessToken().getJwtToken());
			setUser(user);
			if (user) {
				setUserId(user.externalId.toString());
				pendo.identify(user.id.toString());
			}
		} catch (error) {
			console.error('Error fetching user', error);
		} finally {
			setLoading(false);
		}
	}, []);

	const handleSocialLogin = useCallback(
		async (accessToken: string) => {
			try {
				const response = await fetch(
					`${import.meta.env.VITE_API_BASE_URL}/auth/social-login`,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${accessToken}`,
						},
					},
				);

				if (!response.ok) {
					const errorData = await response.json();
					notifyError(`Error: ${errorData.message || response.statusText}`);
					return;
				}
				await fetchUser();
			} catch (error) {
				notifyError(`${error}`);
			}
		},
		[fetchUser],
	);

	useEffect(() => {
		const handleAuthEvent: HubCallback = async (capsule: HubCapsule) => {
			const { event, data } = capsule.payload;
			switch (event) {
				case 'signIn': {
					const tokens = await AuthAmplify.currentSession();
					await handleSocialLogin(tokens.getAccessToken().getJwtToken());
					break;
				}
				case 'signOut':
					logout();
					break;
				case 'signIn_failure':
					console.error('Sign in failure:', data);
					break;
			}
		};

		const unsubscribe = Hub.listen('auth', handleAuthEvent);
		checkAuthStatus();
		return unsubscribe;
	}, [fetchUser]);

	const checkAuthStatus = async () => {
		try {
			setLoading(true);
			await AuthAmplify.currentAuthenticatedUser();
			const tokens = await AuthAmplify.currentSession();
			const user = await getUser(tokens.getAccessToken().getJwtToken());
			setUser(user);
			if (user) {
				setUserId(user.externalId.toString());
				pendo.identify(user.id.toString());
			}
		} catch (error) {
			console.log('Not signed in');
		} finally {
			setLoading(false);
		}
	};

	const loginWithFacebook = () => {
		return AuthAmplify.federatedSignIn({
			provider: CognitoHostedUIIdentityProvider.Facebook,
		});
	};

	const loginWithGoogle = () => {
		return AuthAmplify.federatedSignIn({
			provider: CognitoHostedUIIdentityProvider.Google,
		});
	};

	const login = async (loginData: LoginData): Promise<void> => {
		const user = await authenticateUser(loginData);
		if (user) setUser(user);
		if (user) {
			setUserId(user.externalId.toString());
			pendo.identify(user.id.toString());
		}
	};

	const logout = () => {
		logoutUser();
		setUser(null);
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				login,
				loginWithFacebook,
				loginWithGoogle,
				logout,
				setUser,
				loading,
				fetchUser,
				fakeUserHasSubscription,
				userHasSubscription,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};
