import yellowClockIcon from '@/assets/svg/clock-yellow-icon.svg';
import eyeIcon from '@/assets/svg/eye-icon.svg';
import likeIcon from '@/assets/svg/like-icon.svg';
import redOvenIcon from '@/assets/svg/red-oven-icon.svg';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';

interface RecipeBasicInformationProps {
	title: string;
	time: string;
	difficulty: string;
	servings: string;
	likes: number;
	views: number;
}

export const getRandomBetween = (min: number, max: number): number => {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const RecipeBasicInformation = ({
	title,
	time,
	difficulty,
	servings,
	likes,
	views,
}: RecipeBasicInformationProps) => {
	return (
		<div
			data-cy="detail-page-recipe-basic-information"
			className="flex flex-col gap-y-4 min-[500px]:items-center lg:items-start"
		>
			<h3 className="text-4xl md:text-6xl font-semibold font-roboto ">
				{title}
			</h3>
			<div className="flex gap-x-8 text-grayText">
				<div className="flex items-center">
					<img src={eyeIcon} alt="eye-icon" />
					<p className="ml-1 flex items-center">
						<span className="text-slate-700 font-bold mr-1">{views}</span> Views
					</p>
				</div>
				<div className="flex items-center">
					<img src={likeIcon} alt="like-icon" />
					<p className="ml-1 flex items-center">
						<span
							data-cy="detail-page-recipe-basic-information-likes"
							className="text-slate-700 font-bold mr-1"
						>
							{likes}
						</span>{' '}
						Saves
					</p>
				</div>
			</div>
			<div className="w-full flex gap-2 flex-wrap text-center text-[14px] leading-[14px] tracking-[0.02em] ">
				<div className="flex items-center justify-center gap-x-1 buttons-shape text-brandRed border-2 border-brandRed">
					<img src={yellowClockIcon} alt="clock-icon" />
					<span>{time}</span>
					<span>minutes</span>
				</div>
				<div className="flex items-center justify-center gap-x-1 buttons-shape text-brandRed border-2 border-brandRed">
					<img src={redOvenIcon} alt="clock-icon" />
					<span>Difficulty:</span>
					<span>{upperCaseFirstLetter(difficulty)}</span>
				</div>
				<div className="flex items-center justify-center gap-x-1 buttons-shape text-brandRed border-2 border-brandRed">
					<span>Servings:</span>
					<span>{servings}</span>
				</div>
			</div>
		</div>
	);
};
