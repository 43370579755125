import { logEvent as logEventAmplitude } from '@amplitude/analytics-browser';
import { useRef, useState } from 'react';

import { RenderVideoOrImage } from '../RenderVideoOrImage';

import lockBlackIcon from '@/assets/svg/lock-black-icon.svg';
import { MasonryCardInfo } from '@/components/common/recipe/masonry-recipe/MasonryCardInfo';
import { CardInfoProps } from '@/components/common/recipe/recipe-card/CardInfo';
import useWindowWidth from '@/hooks/useWindowWidth';
import { logVideoPlay } from '@/service/analytics/analytics';

interface RecipeCardProps
	extends Omit<CardInfoProps, 'setIndexToFavouriteAndSaved'> {
	height: 'normal' | 'higher' | 'high';
	isVideo?: boolean;
	mediaUrl?: string;
	thumbnailUrl?: string;
	userHasSubscription?: boolean;
	disableHoverEffect?: boolean;
	disableNavigation?: boolean;
}

export const RecipeDinamicCard = ({
	recipe,
	height,
	mediaUrl,
	isVideo,
	thumbnailUrl,
	userHasSubscription,
	disableHoverEffect = false,
	disableNavigation = false,
}: RecipeCardProps) => {
	const [play, setPlay] = useState(false);
	const [isVideoFetched, setIsVideoFetched] = useState(false);
	const [isMuted, setIsMuted] = useState(true);
	const hasThumbnail = isVideo && thumbnailUrl;
	const [recipeOverEffect, setRecipeOverEffect] = useState(false);
	const dontHaveAccessToRecipe = !userHasSubscription;
	const hoverTimeoutRef = useRef<number | null>(null);
	const windowWidth = useWindowWidth();

	const handlePlayToggle = () => {
		setIsVideoFetched(true);
		setPlay(!play);
	};

	const handlePlayClick = () => {
		if (isVideo) {
			const chefFullName = `${recipe.chef?.user?.name || 'Unknown'} ${
				recipe.chef?.user?.last_name || 'Unknown'
			}`;
			logVideoPlay(recipe.uuid, recipe.title, chefFullName);
			logEventAmplitude('video_play_button', {
				recipe_id: recipe.uuid,
				recipe_title: recipe.title,
				chef_full_name: chefFullName,
			});
		}
	};

	const handleMouseEnter = () => {
		if (disableHoverEffect) {
			setIsMuted(false);
			isVideo && handlePlayToggle();
			isVideo && handlePlayClick();
			return;
		}
		if (hoverTimeoutRef.current === null) {
			hoverTimeoutRef.current = window.setTimeout(() => {
				windowWidth > 1024 && setRecipeOverEffect(true);
				setIsMuted(false);
				isVideo && handlePlayToggle();
				isVideo && handlePlayClick();
			}, 1000);
		}
	};

	const handleMouseLeave = () => {
		if (disableHoverEffect) {
			setIsMuted(true);
			isVideo && handlePlayToggle();
			return;
		}
		if (hoverTimeoutRef.current !== null) {
			setIsMuted(true);
			setRecipeOverEffect(false);
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
			play && handlePlayToggle();
		}
	};

	let imageStyle = '';
	switch (height) {
		case 'normal':
			imageStyle = 'h-[240px] md:h-[300px]';
			break;
		case 'high':
			imageStyle = 'h-[300px] md:h-[360px]';
			break;
		case 'higher':
			imageStyle = 'h-[340px] md:h-[420px]';
			break;
		default:
			imageStyle = 'h-[240px] md:h-[300px]';
			break;
	}

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			data-cy="recipe-dinamic-card"
			className={`relative flex flex-col max-w-[235px] w-[250px] min-[375px]:w-full 2xl:w-[235px] ${
				recipeOverEffect &&
				'bg-white shadow-card rounded-3xl shadow-sm shadow-gray-600 z-20'
			}`}
			style={
				recipeOverEffect
					? {
							animationName: 'card',
							animationDuration: '0.6s',
							animationFillMode: 'forwards',
							animationTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
					  }
					: undefined
			}
		>
			{dontHaveAccessToRecipe && (
				<div className={`absolute top-2 left-2 p-2 rounded-full bg-white z-10`}>
					<img
						src={lockBlackIcon}
						alt="lock"
						className="w-4"
						data-cy="lock-icon"
					/>
				</div>
			)}
			{RenderVideoOrImage({
				isVideoFetched,
				mediaUrl,
				hasThumbnail,
				play,
				isMuted,
				isVideo,
				imageStyle,
				handlePlayToggle: () => {
					handlePlayToggle();
					handlePlayClick();
				},
				recipeUuid: recipe.uuid,
				recipeTitle: recipe.title,
				chefFullName: `${recipe.chef?.user?.name || 'Unknown'} ${
					recipe.chef?.user?.last_name || 'Unknown'
				}`,
				containerStyle: 'w-full relative',
				onPlayClick: handlePlayClick,
				disableNavigation: disableNavigation,
			})}

			<MasonryCardInfo
				recipe={recipe}
				disableNavigation={disableNavigation}
				recipeOverEffect={recipeOverEffect}
			/>
		</div>
	);
};
