import emily from '@/assets/image/chef-1.png';
import recipe from '@/assets/svg/recipe.svg';
import { FollowButton } from '@/components/common/buttons/FollowButton';
import { CdnImage } from '@/components/images/CdnImage';
import { Chef } from '@/context/interfaces';

interface TrendigChefCardProps {
	props: Chef;
	showFollowButton?: boolean;
}

export const ChefCard = ({ props, showFollowButton }: TrendigChefCardProps) => {
	const profileImage = props.user?.profile_image?.src
		? props.user?.profile_image?.src
		: emily;

	const handleClick = (
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		event.stopPropagation();
		event.preventDefault();
	};

	return (
		<div
			data-cy="trending-chef-card"
			className="w-[80vw] flex flex-col gap-y-2 sm:w-[400px] sm:flex-row sm:gap-x-1"
		>
			<div className="flex flex-col justify-between gap-y-2 sm:w-[200px] bg-slate-100 p-6 rounded-3xl">
				<div>
					<h3 className="font-semibold">
						{props?.user?.name} {props?.user?.last_name}
					</h3>
					<p className="text-sm text-slate-400 line-clamp-6">{props?.bio}</p>
				</div>
				<div className="flex flex-col items-start text-[10px] gap-y-2 -tracking-tight font-helveticaNeue">
					<div className="flex justify-center items-center gap-x-2">
						<img src={recipe} alt="recipe-icon" className="w-3" />
						<span className="text-friendlyRed font-semibold truncate max-w-[80px]">
							{props?.numberOfRecipes}
						</span>
						<span>Recipes</span>
					</div>
				</div>
			</div>
			<div className="relative">
				<CdnImage
					alt="chef-photo"
					src={profileImage}
					className="hidden sm:block w-[200px] h-full max-h-[250px] rounded-3xl object-cover"
					height={250}
					width={200}
				/>
				{!showFollowButton && (
					<span
						className="w-full h-7 md:h-5 flex absolute bottom-0 right-0 rounded-b-3xl"
						onClick={handleClick}
					>
						<FollowButton chef={props} isCardStyle />
					</span>
				)}
			</div>
		</div>
	);
};
