import { useQuery } from '@tanstack/react-query';

import { getChefByUuid } from '../../service/api/chef';

export const useFetchChefById = (uuid: string | null) => {
	const chefUuid = uuid ? uuid : '';
	const {
		data: chef,
		isLoading: isLoadingChef,
		isFetching: isFetchingChef,
		isError: isErrorLoadingChef,
		error: errorChef,
	} = useQuery({
		queryKey: ['chef', chefUuid],
		queryFn: () => {
			return getChefByUuid(chefUuid);
		},
	});

	return {
		chef,
		isLoadingChef,
		isFetchingChef,
		isErrorLoadingChef,
		errorChef,
	};
};
