import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Loader } from '@/components/common/Loader';
import { Title } from '@/components/common/Title/Title';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { ChefCard } from '@/components/home/components/ChefCard';
import { ChefSortBar } from '@/components/home/components/ChefSortBar';
import { useGetAllChefs } from '@/hooks/chef/useGetAllChefs';
import { usePagination } from '@/hooks/usePagination';
import { ChefSort } from '@/interface/filter.interface';

export const AllChefsPage = () => {
	const [skip, setSkip] = useState(0);
	const [sort, setSort] = useState<ChefSort>(ChefSort.MOST_POPULAR);
	const take = 12;

	const {
		chefs,
		isErrorLoadingChefs,
		isLoadingChefs,
		totalEntities,
		refetchChefs,
		errorChefs,
	} = useGetAllChefs({ take, skip, sort });
	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	useEffect(() => {
		refetchChefs();
	}, [sort, skip]);

	if (isLoadingChefs)
		return (
			<div className="flex justify-center items-center mt-[15vh]">
				<Loader size={4} />
			</div>
		);

	if (isErrorLoadingChefs) {
		return (
			<div
				className="flex mt-20 justify-center items-center  flex-col w-full   gap-6"
				data-cy="all-chef-page-error-message"
			>
				<p className="text-black text-3xl sm:text-4xl text-center font-sans font-bold tracking-tighter w-[50%]">
					{errorChefs?.message}
				</p>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-center gap-y-6 py-5 lg:-py-10 sm:px-6 px-[20px]">
			<div className="flex flex-col items-center gap-y-2 lg:gap-y-0">
				<Title
					textTitle="Creators"
					titleWithColor="on Kitched"
					colorText="brandRed"
				/>
				<small
					className="text-slate-400 text-center text-sm sm:text-lg 2xl:text-xl font-helveticaNeue"
					data-cy="description"
				>
					Get inspired with recipe ideas and tips from your favorite food
					creators on Kitched
				</small>
			</div>

			<ChefSortBar sort={sort} setSort={setSort} />

			<div className="mt-5 grid place-items-center grid-cols-1 gap-8 min-[900px]:grid-cols-2 xl:grid-cols-3 ">
				{chefs?.data?.map((chef) => (
					<Link
						to={`${chef.user.name}-${chef.user.last_name}?id=${chef.uuid}`}
						key={chef.uuid}
						className="cursor-pointer"
					>
						<ChefCard props={chef} />
					</Link>
				))}
			</div>

			<div className="flex py-5">
				<NavigationButtons
					currentPage={currentPage}
					totalPages={totalPages}
					handleNextClick={onHandleNext}
					handlePrevClick={onHandlePrevious}
				/>
			</div>
		</div>
	);
};
