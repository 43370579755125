import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';

export const AMPLITUDE_API_KEY = import.meta.env.VITE_AMPLITUDE_API_KEY;

class Analytics {
	init() {
		// Start session replays
		const sessionReplayTracking = sessionReplayPlugin({
			sampleRate: 0.5,
		});
		amplitude.add(sessionReplayTracking);

		amplitude.init(AMPLITUDE_API_KEY, {
			defaultTracking: true,
			autocapture: true,
		});
	}

	logEvent(eventName: string, properties?: Record<string, any>) {
		amplitude.track(eventName, properties);
	}

	setUserProperties(userId: string, properties: Record<string, any>) {
		amplitude.setUserId(userId);
		// Iterate through properties and set each key-value pair individually
		const identify = new amplitude.Identify();
		Object.entries(properties).forEach(([key, value]) => {
			identify.set(key, value);
		});
		amplitude.identify(identify);
	}
}

export const analytics = new Analytics();
