import { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { Loader } from '@/components/common/Loader';
import Table from '@/components/common/Table';
import { User } from '@/context/interfaces';
import { useGetAllUsers } from '@/hooks/user/useGetAllUsers';

const { Tr, Th, Td, THead, TBody } = Table;

export const UsersTable = () => {
	const [users, setUsers] = useState<User[]>([]);
	const [skip, setSkip] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const take = 18;

	const { allUsers, isLoadingAllUsers, isErrorAllUsers } = useGetAllUsers(
		take,
		skip,
	);

	useEffect(() => {
		if (allUsers) {
			setUsers((prevUser) => [...prevUser, ...allUsers.data]);
			setHasMore(allUsers.data.length > 0);
		}
	}, [allUsers]);

	const fetchMoreData = () => {
		if (!isLoadingAllUsers && hasMore) {
			setSkip((prevSkip) => prevSkip + take);
		}
	};

	if (isLoadingAllUsers && users.length === 0) {
		return (
			<div className="flex justify-center items-center">
				<Loader size={4} />
			</div>
		);
	}

	if (isErrorAllUsers) {
		return (
			<div
				data-cy="explore-users-error"
				className="flex justify-center text-2xl items-center text-alertRed mt-5 mb-5"
			>
				<p>Error loading users</p>
			</div>
		);
	}

	if (users.length === 0) {
		return (
			<div className="flex justify-center text-3xl/[34px] items-center pt-10">
				<p>No users yet</p>
			</div>
		);
	}

	return (
		<div className="mx-10 mt-10">
			<InfiniteScroll
				dataLength={users.length}
				next={fetchMoreData}
				hasMore={hasMore}
				loader={
					<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl">
						Loading more users...
					</p>
				}
				endMessage={
					<p className="flex justify-center mt-12 mb-10 font-semibold tracking-tight text-2xl">
						No more users
					</p>
				}
			>
				<Table>
					<THead>
						<Tr>
							<Th>Name</Th>
							<Th>Email</Th>
							<Th>Referring Chef</Th>
							<Th>Subscriptions</Th>
						</Tr>
					</THead>
					<TBody>
						{users?.map((user: User, index) => (
							<Tr key={index}>
								<Td>
									{user.name} {user.last_name}
								</Td>
								<Td>{user.email}</Td>
								<Td>
									{user.referringChefUser
										? `${user.referringChefUser?.user.name} ${user.referringChefUser?.user.last_name}`
										: '-'}
								</Td>
								<Td>{user.chef && 'This user is a chef'}</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</InfiniteScroll>
		</div>
	);
};
