import { useFormik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import hidePasswordImg from '@/assets/svg/hide-password.svg';
import showPasswordImg from '@/assets/svg/show-password.svg';
import { Loader } from '@/components/common/Loader';
import { SocialIcons } from '@/components/sign-up/social-icons/SocialIcons';
import { TitleLogin } from '@/components/sign-up/title/TitleLogin';
import { useAuth } from '@/context/useAuth';
import {
	LOGIN_COMMON_ERROR,
	LOGIN_SUCCESSFUL,
	NOT_CONFIRMED_USER_COGNITO_ERROR,
	NOT_CONFIRMED_USER_ERROR,
	USER_NOT_AUTHORIZED_COGNITO_ERROR,
	USER_NOT_EXISTS,
} from '@/error/authentication.notify.messages';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { loginValidationSchema } from '@/helper/validations/login.validations';

export const Form = () => {
	const { login, loginWithFacebook, loginWithGoogle } = useAuth();
	const navigate = useNavigate();
	const validationSchema = loginValidationSchema();
	const [showPassword, setShowPassword] = useState(false);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			rememberMe: false,
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await login(values);
				notifySuccess(LOGIN_SUCCESSFUL);
				navigate('/home');
			} catch (error: unknown) {
				let message = LOGIN_COMMON_ERROR;
				if (error instanceof Error) {
					message = error.message;
					if (error.message === NOT_CONFIRMED_USER_COGNITO_ERROR)
						message = NOT_CONFIRMED_USER_ERROR;
					if (error.message === USER_NOT_AUTHORIZED_COGNITO_ERROR)
						message = USER_NOT_EXISTS;
				}
				notifyError(message);
			} finally {
				setSubmitting(false);
			}
		},
	});

	const handleLoginWithFacebook = async () => {
		try {
			await loginWithFacebook();
		} catch (error) {
			console.error('Facebook login error:', error);
		}
	};

	const handleLoginWithGoogle = async () => {
		try {
			await loginWithGoogle();
		} catch (error) {
			console.error('Google login error:', error);
		}
	};

	return (
		<div className="w-full h-[509px] mt-[114px] mb-[147px] flex flex-col ">
			<TitleLogin />

			<SocialIcons />

			<form
				className="flex flex-col gap-[42px] h-[252px] max-w-lg mx-auto   mt-12"
				onSubmit={formik.handleSubmit}
			>
				<div className="gap-[16px] h-[158px]">
					<div className="flex flex-col gap-[16px] w-[320px] h-[122px]">
						<div className="relative">
							<input
								data-cy="login-input-email"
								type="email"
								name="email"
								placeholder="Enter your email"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.email}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50"
							/>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="email">EMAIL</label>
							</div>

							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.email && formik.errors.email && (
									<div>{formik.errors.email}</div>
								)}
							</div>
						</div>

						<div className="relative">
							<div className="flex">
								<input
									data-cy="login-input-password"
									type={showPassword ? 'text' : 'password'}
									name="password"
									placeholder="Enter your password"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.password}
									className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandRed focus:bg-white bg-slate-50 pr-10"
								/>
								<button
									type="button"
									onClick={() => setShowPassword(!showPassword)}
									className="absolute right-3 top-1/2 transform -translate-y-1/2"
								>
									<img
										src={showPassword ? showPasswordImg : hidePasswordImg}
										alt="password-icon"
										className="w-[20px] h-[20px]"
									/>
								</button>
							</div>
							<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
								<label htmlFor="password">PASSWORD</label>
							</div>
							<div className="text-alertRed pl-6 text-[9px]">
								{formik.touched.password && formik.errors.password && (
									<div>{formik.errors.password}</div>
								)}
							</div>
						</div>
					</div>

					<div className="flex items-center gap-1 mt-5 w-[320px] h-5">
						<input
							data-cy="login-checkbox"
							type="checkbox"
							name="rememberMe"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							checked={formik.values.rememberMe}
							className="w-5 h-4 rounded-sm accent-brandRed"
						/>
						<div className="flex justify-between flex-1">
							<label
								htmlFor="rememberMe"
								className="text-[13px] -mt-[3px] text-gray-90"
							>
								Remember Me
							</label>
						</div>

						<div className="flex flex-col gap-1.5">
							<Link
								to="/forgot-password"
								data-cy="forgot-password-link"
								className="text-[12px] -mt-[3px] cursor-pointer text-brandRed hover:underline"
							>
								Forgot password?
							</Link>
							<Link
								to="/resend-verification"
								data-cy="resend-verification-link"
								className="text-[12px] -mt-[3px] cursor-pointer text-brandRed hover:underline"
							>
								Resend verification
							</Link>
						</div>
					</div>
				</div>

				<button
					data-cy="login-submit"
					type="submit"
					className="w-[156px] h-[52px] rounded-full bg-brandRed text-white flex justify-center items-center mx-auto  transition-all hover:text-brandRed hover:bg-white hover:ring-2 hover:ring-brandRed hover:cursor-pointer"
					disabled={formik.isSubmitting}
					onSubmit={formik.submitForm}
				>
					{formik.isSubmitting ? (
						<Loader size={1.4} />
					) : (
						<span className="text-[15px] font-semibold">Log In</span>
					)}
					<span className="p-[4px] bg-brandRed rounded-full border-2 border-white absolute ml-36">
						<img src={arrowUpRight} alt="arrow-icon" width="24" />
					</span>
				</button>
			</form>

			<div className="flex items-center justify-center pt-8">
				<p className="p-2 md:p-0 md:mr-1 text-sm md:text-md text-loginTextGrey">
					Don’t have an account yet?
				</p>
				<Link
					to="/signup"
					data-cy="sign-up-redirect"
					className="text-sm md:text-md cursor-pointer text-brandRed hover:underline"
				>
					Create account
				</Link>
			</div>

			<div className="flex flex-col gap-4 items-center pt-6">
				<button
					onClick={handleLoginWithFacebook}
					type="button"
					className="w-[320px] h-[52px] rounded-full bg-[#1877F2] text-white flex justify-center items-center gap-2 transition-all hover:bg-[#0c5bbd]"
				>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
						<path d="M9.198 21.5h4v-8.01h3.604l.396-3.98h-4V7.5a1 1 0 0 1 1-1h3v-4h-3a5 5 0 0 0-5 5v2.01h-2l-.396 3.98h2.396v8.01Z" />
					</svg>
					<span className="text-[15px] font-semibold">
						Continue with Facebook
					</span>
				</button>

				<button
					onClick={handleLoginWithGoogle}
					type="button"
					className="w-[320px] h-[52px] rounded-full bg-white text-gray-700 border border-gray-300 flex justify-center items-center gap-3 transition-all hover:bg-gray-50"
					data-cy="google-login"
				>
					<svg width="24" height="24" viewBox="0 0 24 24">
						<path
							fill="#4285F4"
							d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
						/>
						<path
							fill="#34A853"
							d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
						/>
						<path
							fill="#FBBC05"
							d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
						/>
						<path
							fill="#EA4335"
							d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
						/>
					</svg>
					<span className="text-[15px] font-semibold">
						Continue with Google
					</span>
				</button>
			</div>
		</div>
	);
};
