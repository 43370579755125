import { useState } from 'react';
import { Link } from 'react-router-dom';

import eyeIcon from '@/assets/svg/eye-icon.svg';
import likeIcon from '@/assets/svg/like-icon.svg';
import saveRecipeBlackIcon from '@/assets/svg/save-recipe-black-icon.svg';
import saveRecipeIcon from '@/assets/svg/save-recipe-color-icon.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { CdnImage } from '@/components/images/CdnImage';
import { getRandomBetween } from '@/components/recipe-detail/RecipeBasicInformation';
import { useManageSavedRecipe } from '@/hooks/user-saved/useManageSavedRecipe';
import { IRecipe } from '@/interface/recipe.interfaces';

export interface CardInfoProps {
	recipe: IRecipe;
	isChefProfile?: boolean;
	disableNavigation?: boolean;
	recipeOverEffect?: boolean;
}

export const MasonryCardInfo = ({
	recipe,
	isChefProfile,
	disableNavigation = false,
	recipeOverEffect,
}: CardInfoProps) => {
	const { isSaved, addSavedRecipe, deleteSavedRecipe } =
		useManageSavedRecipe(recipe);

	const { title, chef, uuid } = recipe;
	const [randomizedSaves, _setRandomizedSaves] = useState(() =>
		getRandomBetween(30, 900),
	);

	const [randomizedViews, _setRandomizedViews] = useState(() =>
		getRandomBetween(2000, 5000),
	);

	const handleSave = () => {
		if (isSaved) {
			deleteSavedRecipe();
		} else {
			addSavedRecipe();
		}
	};

	return (
		<div className="w-full  mb-4 mt-2">
			<Link
				data-cy="recipe-dinamic-card-link"
				to={
					disableNavigation
						? '#'
						: `/recipe/detail/${title.replace(/\s+/g, '-')}?id=${uuid}`
				}
			>
				<div className="w-full flex px-1 gap-2.5 font-light text-xs text-grayText">
					<div className="flex items-center">
						<img src={eyeIcon} alt="eye-icon" className="w-4 h-4" />
						<p className="ml-1 flex items-center font-bold mr-1">
							{/* Randomise selection whilst not currently dynamic */}
							{randomizedViews}
						</p>
					</div>
					<div className="flex items-center">
						<img src={likeIcon} alt="like-icon" className="w-4 h-4" />
						<p
							className="ml-1 flex items-center font-bold mr-1"
							data-cy="like-counter"
						>
							{randomizedSaves}
						</p>
					</div>
				</div>
				<p
					className={`h-[30px] text-xl mb-0.5 px-2 font-bold tracking-tight break-words hover:text-brandRed ${
						recipeOverEffect && title.length > 20
							? 'min-[420px]:text-xs min-[420px]:leading-4 tracking-tighter line-clamp-2'
							: 'truncate'
					} `}
				>
					{title}
				</p>
			</Link>

			<div className="w-full flex items-center px-1.5 gap-2.5 font-light text-grayText">
				{!isChefProfile && (
					<div className="flex items-center gap-2 text-sm font-light tracking-normal text-gray-600">
						<CdnImage
							height={20}
							width={20}
							src={chef?.user?.profile_image?.src || userBlackIcon}
							alt="avatar"
							className="object-cover rounded-full"
						/>
						<p className="text-xs truncate font-helveticaNeue tracking-normal">{`${chef?.user?.name} ${chef?.user?.last_name}`}</p>
					</div>
				)}

				<div className="flex-1"></div>
				<img
					src={isSaved ? saveRecipeIcon : saveRecipeBlackIcon}
					alt="saved-icon"
					className="w-4 h-4 hover:scale-110 hover:cursor-pointer transform"
					data-cy="save-icon"
					onClick={handleSave}
				/>
			</div>
		</div>
	);
};
