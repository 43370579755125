import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import Api from '@/service';

const RESOURCE = 'product-link';

export interface IProductLink {
	id: number;
	name: string;
	thumbnailUrl: string;
	affiliateUrl: string;
}

export interface CreateProductLinkDto {
	name: string;
	thumbnailUrl: string;
	affiliateUrl: string;
}

// Error messages enum (you might want to move this to a separate file)
export enum PRODUCT_LINK_ERRORS_MESSAGES {
	CREATE_ERROR = 'Failed to create product link',
	GET_ALL_ERROR = 'Failed to fetch product links',
	GET_BY_ID_ERROR = 'Failed to fetch product link',
	DELETE_ERROR = 'Failed to delete product link',
	NOT_FOUND = 'Product link not found',
}

export const createProductLink = async (
	newProduct: CreateProductLinkDto,
): Promise<IProductLink> => {
	try {
		const { data } = await Api.post(`/${RESOURCE}`, newProduct);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			PRODUCT_LINK_ERRORS_MESSAGES.CREATE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const getAllProductLinks = async (): Promise<IProductLink[]> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}`);
		return data;
	} catch (error: unknown) {
		console.log({ error });
		throw new Error(PRODUCT_LINK_ERRORS_MESSAGES.GET_ALL_ERROR);
	}
};

export const getProductLinkById = async (id: number): Promise<IProductLink> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/${id}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(PRODUCT_LINK_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(PRODUCT_LINK_ERRORS_MESSAGES.GET_BY_ID_ERROR);
	}
};

export const deleteProductLink = async (id: number): Promise<void> => {
	try {
		await Api.delete(`/${RESOURCE}/${id}`);
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			PRODUCT_LINK_ERRORS_MESSAGES.DELETE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};
