import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import ReactDOM from 'react-dom/client';
import { clarity } from 'react-microsoft-clarity';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './app.css';
import './index.css';
import pendo from './interface/pendo';
import { analytics } from './service/analyticsV2/analytics';

import { AuthProvider } from '@/context/AuthProvider';

import router from '@configs/router';

const queryClient = new QueryClient();

if (import.meta.env.MODE === 'production')
	clarity.init(import.meta.env.VITE_CLARITY_KEY);

pendo.initialize({
	visitor: {
		id: 'anonymous',
	},
});

Amplify.configure({
	Auth: {
		region: import.meta.env.VITE_COGNITO_REGION,
		userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
		userPoolWebClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
		oauth: {
			domain: import.meta.env.VITE_COGNITO_DOMAIN,
			scope: ['email', 'openid', 'profile'],
			redirectSignIn: import.meta.env.VITE_COGNITO_CALLBACK,
			responseType: 'code',
		},
	},
});

analytics.init();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<AuthProvider>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={router} />
			<ToastContainer />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</AuthProvider>,
);
