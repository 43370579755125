import { useEffect, useState } from 'react';

import ChangePasswordSettings from './change-password-settings/ChangePasswordSettings';
import { ConcernsAndAllergiesMenu } from './preferences-settings/ConcernsAndAllergiesMenu';
import { CustomChoicesMenus } from './preferences-settings/CustomChoicesMenus';
import { FavouriteCuisinesMenu } from './preferences-settings/FavouriteCuisines';
import ProfileUserSettings from './profile-settings/ProfileUserSettings';
import UpdateProfileImage from './profile-settings/UpdateProfileImage';
import GeneralSettingsButtons from './settings-buttons/GeneralSettingsButtons';

import { Loader } from '@/components/common/Loader';
import { useAuth } from '@/context/useAuth';
import { ScrollToTop } from '@/helper/scrollToTop';

export const SettingsPage = () => {
	const { user } = useAuth();
	const isCustomer = user?.subscription?.customerId;
	const [newUserName, setNewUserName] = useState(user?.name || '');
	const [newUserLastName, setNewUserLastName] = useState(user?.last_name || '');

	const [selectConfigView, setSelectConfigView] = useState('GeneralSettings');
	const isChef = user?.chef?.id;
	const containerFlexProps = isChef
		? 'sm:flex-row sm:justify-between sm:items-center'
		: 'lg:flex-row lg:justify-between lg:items-center';

	useEffect(() => {
		setNewUserName(user?.name || '');
		setNewUserLastName(user?.last_name || '');
	}, [user]);

	if (!user)
		return (
			<div className="flex justify-center items-center h-[80vh]">
				<Loader size={4} />
			</div>
		);

	return (
		<div className="space-y-10 bg-slate-200 my-10">
			<ScrollToTop />
			<div
				className={`flex flex-col items-start py-5 px-5 sm:px-6  gap-4 bg-white rounded-b-3xl  ${containerFlexProps}`}
			>
				<div className="flex flex-col md:flex-row md:items-center gap-2 ">
					<UpdateProfileImage user={user} />

					<div className="flex flex-col space-y-[-6px] -translate-y-[2px]">
						<span data-cy="user-email" className="text-slate-400 text-[13px]">
							{user.email}
						</span>

						<span
							data-cy="user-name"
							className="text-[18px] md:text-[24px] font-semibold"
						>
							{newUserName} {newUserLastName}
						</span>
					</div>
				</div>

				<div data-cy="general-settings-button">
					<GeneralSettingsButtons
						selectConfigView={selectConfigView}
						setSelectConfigView={setSelectConfigView}
						isCustomer={isCustomer}
						isChef={isChef}
					/>
				</div>
			</div>

			{selectConfigView === 'GeneralSettings' && (
				<div className="flex flex-col gap-y-6 items-center px-2 pb-10 lg:flex-row lg:justify-center lg:gap-x-10">
					<div className="w-full pt-10 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<ChangePasswordSettings user={user} />
					</div>
					<div className="w-full pt-10 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<ProfileUserSettings user={user} />
					</div>
				</div>
			)}

			{selectConfigView === 'Preferences' && (
				<div className="flex flex-col gap-y-6 items-center px-2 pb-10 lg:flex-row lg:justify-center lg:items-stretch lg:gap-x-6">
					<div className="w-full py-8 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<CustomChoicesMenus user={user} />
					</div>
					<div className="w-full py-8 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<FavouriteCuisinesMenu user={user} />
					</div>
					<div className="w-full py-8 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-[25%] bg-white rounded-3xl">
						<ConcernsAndAllergiesMenu user={user} />
					</div>
				</div>
			)}
		</div>
	);
};
