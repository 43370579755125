import { logEvent as logEventAmplitude } from '@amplitude/analytics-browser';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { User } from '@/context/interfaces';
import { notifyError, notifyInfo, notifySuccess } from '@/helper/notifications';
import {
	deleteUserSavedRecipe,
	putUserAddSavedRecipe,
} from '@/service/api/user';

interface SaveRecipeData {
	userId: number;
	recipeId: number;
}

const usePutUserSavedRecipe = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation<User, Error, SaveRecipeData, User>({
		mutationFn: async (data: SaveRecipeData) => {
			const { userId, recipeId } = data;
			logEventAmplitude('saved_recipe', {
				recipe_id: recipeId,
				user_id: userId,
			});
			return putUserAddSavedRecipe(userId, recipeId);
		},
		onError: (context) => {
			if (context) {
				queryClient.setQueryData(['user'], context);
			}
			notifyError('Failed to save recipe');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
		onSuccess: () => notifySuccess('Recipe saved successfully'),
	});

	return mutation;
};

const useDeleteUserSavedRecipe = () => {
	const queryClient = useQueryClient();

	const mutation = useMutation<User, Error, SaveRecipeData, User>({
		mutationFn: async (data: SaveRecipeData) => {
			const { userId, recipeId } = data;
			return deleteUserSavedRecipe(userId, recipeId);
		},
		onError: (context) => {
			if (context) {
				queryClient.setQueryData(['user'], context);
			}
			notifyError('Failed to remove recipe');
		},
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: ['user'] });
		},
		onSuccess: () => notifyInfo('Recipe removed successfully'),
	});

	return mutation;
};

export { usePutUserSavedRecipe, useDeleteUserSavedRecipe };
