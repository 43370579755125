import { Link, Outlet, useLocation } from 'react-router-dom';

import instagramColorfulIcon from '@/assets/svg/colorful/instagram.svg';
import { Loader } from '@/components/common/Loader';
import { useAuth } from '@/context/useAuth';
import { ScrollToTop } from '@/helper/scrollToTop';
import useWindowWidth from '@/hooks/useWindowWidth';
import { getInstagramCode } from '@/service/api/instagram';

export const ChefLayoutProfile = () => {
	const { user } = useAuth();
	const location = useLocation();
	const windowWidth = useWindowWidth();

	const onNavigateToInstagramAuthorizationWindow = async (): Promise<void> => {
		const code = await getInstagramCode();

		window.location.href = code;
	};

	if (!user)
		return (
			<div className="flex justify-center items-center h-[80vh]">
				<Loader size={4} />
			</div>
		);

	return (
		<div className="flex flex-col gap-y-10 px-5 sm:px-6  font-helveticaNeue">
			<ScrollToTop />
			<div className="flex flex-col gap-y-10">
				{windowWidth >= 1024 && (
					<div className="flex items-end gap-4 pt-4">
						<Link to={'/chef/profile/products'}>
							<button className="flex items-center gap-x-2 py-2 px-4 text-pink-500 rounded-3xl bg-pink-200 hover:bg-pink-300">
								<span>Manage storefront 🛒</span>
							</button>
						</Link>

						{location.pathname === '/chef/profile/instagram-posts' ? (
							<div className="flex flex-col gap-4 sm:flex-row">
								<Link
									to={'/chef/profile'}
									className="flex items-center gap-x-2 py-2 px-4 text-white rounded-3xl bg-brandRed hover:bg-red-600"
								>
									<span>Your list of recipes in Kitched</span>
								</Link>
								<button
									onClick={onNavigateToInstagramAuthorizationWindow}
									className="flex items-center gap-x-2 py-2 px-4 text-pink-500 rounded-3xl bg-pink-200 hover:bg-pink-300"
								>
									<span className=" font-bold">Connect with Instagram</span>
									<img
										src={instagramColorfulIcon}
										alt="instagram-icon"
										className="w-6"
									/>
								</button>
							</div>
						) : (
							<Link
								data-cy="chef-profile-page-instagram-posts-link"
								to={'/chef/profile/instagram-posts'}
								className="flex items-center gap-x-2 py-2 px-4 rounded-3xl bg-slate-100 hover:bg-gray-300"
							>
								<span className=" font-bold">Your Instagram recipes</span>
								<img
									src={instagramColorfulIcon}
									alt="instagram-icon"
									className="w-6"
								/>
							</Link>
						)}
						{location.pathname !== '/chef/profile/recipes/create' && (
							<div className="flex justify-end">
								<Link to={'/chef/profile/recipes/create'}>
									<button
										className="order-last grow min-[412px]:order-none min-[412px]:grow-0 flex items-center justify-center gap-x-2 py-2 px-4 bg-brandRed rounded-3xl text-white transition-colors hover:bg-red-600"
										data-cy="create-recipe-button"
									>
										Create a recipe manually
									</button>
								</Link>
							</div>
						)}
					</div>
				)}
				<Outlet />
			</div>
		</div>
	);
};
