import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useWindowWidth from '@/hooks/useWindowWidth';
import { CreateCollectionModal } from '@/pages/user/collections/CreateCollectionModal';

interface TabProfileNavProps {
	savedRecipesCount: number;
	createdRecipesCount: number;
	collectionsCount: number;
	activeTab: string;
	setActiveTab: (tab: string) => void;
	isChef: boolean;
	resetSkip: () => void;
	onCreateCollection?: (title: string) => void;
	userHasSubscription?: boolean;
}

export const TabProfileNav = ({
	savedRecipesCount,
	createdRecipesCount,
	collectionsCount,
	activeTab,
	setActiveTab,
	isChef,
	resetSkip,
	onCreateCollection,
	userHasSubscription,
}: TabProfileNavProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const navigate = useNavigate();
	const windowWidth = useWindowWidth();

	const handleCreateCollection = (title: string) => {
		if (onCreateCollection) onCreateCollection(title);
		setIsModalOpen(false);
	};

	const handleTabClick = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<div className="">
			<nav className="flex justify-center md:justify-between  flex-col sm:flex-row gap-4 sm:gap-8 mb-10">
				<div
					className={`flex ${
						isChef ? 'justify-between' : 'justify-center'
					} md:justify-start flex-wrap text-center sm:text-left gap-4 sm:gap-6 md:gap-8`}
				>
					<div
						data-cy="user-saved-recipes"
						className={`cursor-pointer ${
							activeTab === 'saved' ? 'text-brandRed' : 'text-gray-400'
						}  h-[32px] flex flex-col items-start  ${
							windowWidth < 500 && isChef && 'w-1/2'
						} ${windowWidth > 500 && windowWidth < 768 && 'items-center'} ${
							windowWidth < 500 && !isChef && 'items-center'
						}`}
						onClick={() => {
							resetSkip();
							setActiveTab('saved');
						}}
					>
						<p className="text-[11px] md:text-[13px]">
							{savedRecipesCount} Saved
						</p>
						<p className="text-[22px] md:text-[28px] font-semibold tracking-tighter">
							{windowWidth > 1024 ? 'Saved Recipes' : 'Saved'}
						</p>
					</div>

					<div
						data-cy="user-collections"
						className={`cursor-pointer ${
							activeTab === 'collections' ? 'text-brandRed' : 'text-gray-400'
						} w-auto h-[32px] flex flex-col items-start ${
							windowWidth > 500 && windowWidth < 768 && 'items-center'
						}  ${windowWidth < 500 && !isChef && 'items-center'}`}
						onClick={
							!userHasSubscription
								? () => navigate('/subscriptions/message')
								: () => {
										resetSkip();
										handleTabClick('collections');
								  }
						}
					>
						<p className="text-[11px] md:text-[13px]">
							{collectionsCount} Collections
						</p>
						<p className="text-[22px] md:text-[28px] font-semibold tracking-tighter">
							Collections
						</p>
					</div>

					{isChef && (
						<div
							data-cy="user-created-recipes"
							className={`cursor-pointer ${
								activeTab === 'created' ? 'text-brandRed' : 'text-gray-400'
							} w-auto h-[32px] flex flex-col items-start ${
								windowWidth < 500 && 'items-end'
							}	  ${windowWidth > 500 && windowWidth < 768 && 'items-center'}`}
							onClick={() => setActiveTab('created')}
						>
							<p className="text-[11px] md:text-[13px]">
								{createdRecipesCount} Recipes
							</p>
							<p className="text-[22px] md:text-[28px] font-semibold tracking-tighter">
								{windowWidth > 1024 ? 'Created Recipes' : 'Created'}
							</p>
						</div>
					)}
				</div>
				{userHasSubscription && windowWidth > 768 && (
					<button
						className="order-last grow min-[412px]:order-none min-[412px]:grow-0 flex items-center justify-center h-12 gap-x-2 py-4 px-4 bg-brandRed rounded-3xl text-white transition-colors hover:bg-[#E1B864]"
						onClick={() => setIsModalOpen(true)}
						data-cy="create-collection-button"
					>
						Create Collection
					</button>
				)}
			</nav>
			{userHasSubscription &&
				windowWidth < 768 &&
				activeTab === 'collections' && (
					<div className="flex justify-center">
						<button
							className="flex items-center justify-center h-12 gap-x-2 py-4 px-4 bg-brandRed rounded-3xl text-white transition-colors hover:bg-[#E1B864] "
							onClick={() => setIsModalOpen(true)}
						>
							Create Collection
						</button>
					</div>
				)}
			<CreateCollectionModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onCreate={handleCreateCollection}
			/>
		</div>
	);
};
