import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import {
	CreateProductLinkDto,
	IProductLink,
	createProductLink,
	deleteProductLink,
	getAllProductLinks,
} from '@/service/api/productLink';

const ManageProducts = () => {
	const [products, setProducts] = useState<IProductLink[]>([]);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [newProduct, setNewProduct] = useState<CreateProductLinkDto>({
		name: '',
		thumbnailUrl: '',
		affiliateUrl: '',
	});

	const fetchProducts = async () => {
		try {
			const data = await getAllProductLinks();
			console.log({ data });
			setProducts(data);
		} catch (error) {
			if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('Failed to fetch products');
			}
		}
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	const handleAddProduct = async (e: React.FormEvent) => {
		e.preventDefault();
		try {
			await createProductLink(newProduct);
			toast.success('Product added successfully');
			setIsAddModalOpen(false);
			setNewProduct({ name: '', thumbnailUrl: '', affiliateUrl: '' });
			fetchProducts();
		} catch (error) {
			if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('Failed to add product');
			}
		}
	};

	const handleDeleteProduct = async (id: number) => {
		if (window.confirm('Are you sure you want to delete this product?')) {
			try {
				await deleteProductLink(id);
				toast.success('Product deleted successfully');
				fetchProducts();
			} catch (error) {
				if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('Failed to delete product');
				}
			}
		}
	};

	return (
		<div className="p-6">
			<div className="flex justify-between items-center mb-6">
				<h1 className="text-2xl font-bold">Manage Products</h1>
				<button
					onClick={() => setIsAddModalOpen(true)}
					className="bg-brandRed text-white px-4 py-2 rounded-md hover:bg-red-600"
				>
					Add New Product
				</button>
			</div>

			{/* Products Table */}
			<div className="overflow-x-auto">
				<table className="min-w-full bg-white rounded-lg overflow-hidden shadow-md">
					<thead className="bg-gray-100">
						<tr>
							<th className="px-6 py-3 text-left">Name</th>
							<th className="px-6 py-3 text-left">Thumbnail</th>
							<th className="px-6 py-3 text-left">Link</th>
							<th className="px-6 py-3 text-left">Actions</th>
						</tr>
					</thead>
					<tbody>
						{products.map((product) => (
							<tr key={product.id} className="border-t">
								<td className="px-6 py-4">{product.name}</td>
								<td className="px-6 py-4">
									<img
										src={product.thumbnailUrl}
										alt={product.name}
										className="w-20 h-20 object-contain"
									/>
								</td>
								<td className="px-6 py-4">
									<a
										href={product.affiliateUrl}
										target="_blank"
										rel="noopener noreferrer"
										className="text-blue-600 hover:underline"
									>
										Go to link
									</a>
								</td>
								<td className="px-6 py-4">
									<button
										onClick={() => handleDeleteProduct(product.id)}
										className="text-red-600 hover:text-red-800"
									>
										Delete
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>

			{/* Add Product Modal */}
			{isAddModalOpen && (
				<div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
					<div className="bg-white p-6 rounded-lg w-full max-w-md">
						<h2 className="text-xl font-bold mb-4">Add New Product</h2>
						<form onSubmit={handleAddProduct}>
							<div className="space-y-4">
								<div>
									<label className="block mb-1">Name</label>
									<input
										type="text"
										value={newProduct.name}
										onChange={(e) =>
											setNewProduct({ ...newProduct, name: e.target.value })
										}
										className="w-full border rounded-md px-3 py-2"
										required
									/>
								</div>
								<div>
									<label className="block mb-1">Thumbnail URL</label>
									<input
										type="url"
										value={newProduct.thumbnailUrl}
										onChange={(e) =>
											setNewProduct({
												...newProduct,
												thumbnailUrl: e.target.value,
											})
										}
										className="w-full border rounded-md px-3 py-2"
										required
									/>
								</div>
								<div>
									<label className="block mb-1">Affiliate Link</label>
									<input
										type="url"
										value={newProduct.affiliateUrl}
										onChange={(e) =>
											setNewProduct({
												...newProduct,
												affiliateUrl: e.target.value,
											})
										}
										className="w-full border rounded-md px-3 py-2"
										required
									/>
								</div>
							</div>
							<div className="flex justify-end space-x-3 mt-6">
								<button
									type="button"
									onClick={() => setIsAddModalOpen(false)}
									className="px-4 py-2 border rounded-md"
								>
									Cancel
								</button>
								<button
									type="submit"
									className="px-4 py-2 bg-brandRed text-white rounded-md hover:bg-red-600"
								>
									Add Product
								</button>
							</div>
						</form>
					</div>
				</div>
			)}
		</div>
	);
};

export default ManageProducts;
