import { useMutation } from '@tanstack/react-query';

import { useGetUserSavedRecipes } from '../user/useGetUserSavedRecipes';
import { useGetChefRecipes } from './useGetChefRecipes';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { deleteRecipe } from '@/service/api/recipe';

export const useDeleteRecipe = (
	paginationOptions: {
		take: number;
		skip: number;
	},
	isChefProfile?: boolean,
) => {
	const { refetchChefRecipes } = useGetChefRecipes(
		'',
		paginationOptions,
		isChefProfile,
	);
	const { refetchUserSavedRecipes } = useGetUserSavedRecipes(paginationOptions);

	const deleteRecipeMutation = useMutation({
		mutationFn: deleteRecipe,
		onSuccess: () => {
			notifySuccess('Recipe deleted successfully');
			refetchChefRecipes();
			refetchUserSavedRecipes();
		},
		onError: (error: Error) => notifyError(`${error.message}`),
	});

	return { deleteRecipeMutation };
};
